import React, { useEffect } from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';

const TicketsDetalle = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => {
  console.log('Drawing TicketsDetalle');
  useEffect(() => {
    console.log('Redrawing TicketsDetalle.');
  });
  return (
    <Paper style={{ padding: '1em 1em 3em 1em' }}>
      {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom={true}>
            {`${reportData.nombre}: ${reportTitle}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom={true} align="right">
            <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
            {formattedStartDate} / {formattedEndDate}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <TableContainer
          component={({ children }) => <Grid item>{children}</Grid>}
        >
          <Table size="small" id="report-table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>TICKET</TableCell>
                <TableCell>DPTO</TableCell>
                <TableCell>PLU</TableCell>
                <TableCell align="right">VENDEDOR</TableCell>
                <TableCell>CANTIDAD</TableCell>
                <TableCell>Dcto.(€)</TableCell>
                <TableCell>IMPORTE(€)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.detalle.map((row: any, idx: number) => (
                <>
                  <TableRow key={idx}>
                    <TableCell
                      colSpan={8}
                      style={{ backgroundColor: 'lightgray' }}
                    >
                      {row.fecha}
                    </TableCell>
                  </TableRow>
                  {row.tickets.map((ticket: any, idx2: number) => (
                    <>
                      <TableRow key={idx + ',' + idx2}>
                        <TableCell></TableCell>
                        <TableCell align="right">{ticket.ticket}</TableCell>
                        <TableCell colSpan={2}>{ticket.hora}</TableCell>
                        <TableCell align="right">{ticket.vendedor}</TableCell>
                        <TableCell colSpan={3} align="right">
                          {ticket.importe}
                        </TableCell>
                      </TableRow>
                      {ticket.lineas.map((linea: any, idx3: number) => (
                        <TableRow key={idx + ',' + idx2 + ',' + idx3}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{linea.dpto}</TableCell>
                          <TableCell colSpan={2}>
                            {linea.plu + ' ' + linea.descrip}
                          </TableCell>
                          <TableCell align="right">
                            {linea.peso} {linea.unidades}
                          </TableCell>
                          <TableCell align="right">{linea.dcto}</TableCell>
                          <TableCell align="right">{linea.importe}</TableCell>
                        </TableRow>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Paper>
  );
};

export default React.memo(TicketsDetalle);
