import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import jwt from 'jsonwebtoken';

interface OAuth2ManagerConfig {
  appURL: string;
  clientId: string;
  oauth2TokenEP: string;
  oauth2LogoutEP: string;
  oauth2PasswordChangeEP: string;
  scope: string;
}

interface OAuth2Token {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  date: Date;
}

export class OAuth2Manager {
  private config: OAuth2ManagerConfig;
  private oauth2Token?: OAuth2Token | null = null;

  private async tokenRequest(code: string, verifier: string): Promise<any> {
    return await fetch(`${this.config.oauth2TokenEP}`, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: this.config.clientId,
        redirect_uri: this.config.appURL,
        code_verifier: verifier,
        scope: this.config.scope,
        code,
      }),
    }).then((response) => {
      if (response.ok) return response.json();
      else throw new Error(response.statusText);
    });
  }

  private async tokenRefresh(
    refreshToken: string,
    scope?: string
  ): Promise<any> {
    return await fetch(`${this.config.oauth2TokenEP}`, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: this.config.clientId,
        refresh_token: refreshToken,
      }),
    }).then((response) => {
      if (response.ok) return response.json();
      else throw new Error(response.statusText);
    });
  }

  constructor(config: OAuth2ManagerConfig) {
    this.config = config;
  }

  async login(code: string, verifier: string): Promise<void> {
    console.log('AuthProvider: login', code, verifier);
    this.oauth2Token = {
      ...(await this.tokenRequest(code, verifier)),
      date: new Date(),
    };
    console.log('Token', this.oauth2Token);
  }

  async refresh(): Promise<void> {
    // Time to refresh token.
    if (this.oauth2Token) {
      this.oauth2Token = {
        ...(await this.tokenRefresh(
          this.oauth2Token.refresh_token,
          this.oauth2Token.scope
        )),
        date: new Date(),
      };
      console.log('Token refreshed', this.oauth2Token);
    } else throw new Error(`Can't refresh null session.`);
  }

  async changePassword(): Promise<void> {
    console.log('AuthProvider: changePassword');
    window.location.href = `${this.config.oauth2PasswordChangeEP}?scope=${
      this.config.scope
    }&redirect_uri=${encodeURIComponent(this.config.appURL)}`;
  }

  async logout(): Promise<void> {
    console.log('AuthProvider: logout');
    this.oauth2Token = null;
    window.location.href = `${
      this.config.oauth2LogoutEP
    }?redirect_uri=${encodeURIComponent(this.config.appURL)}`;
  }

  async checkAuth(): Promise<void> {
    if (this.oauth2Token) {
      const expirationDate = dayjs(this.oauth2Token.date).add(
        this.oauth2Token.expires_in,
        'seconds'
      );
      const refreshTime = expirationDate.subtract(300, 'seconds');
      const now = dayjs();
      if (now.isAfter(refreshTime)) {
        if (now.isBefore(expirationDate)) {
          await this.refresh();
        } else {
          throw new Error('Session expired.');
        }
      }
    }
  }

  getAuthorizationHeader(): string {
    console.log('AuthProvider: getAuthorizationHeader');
    if (this.oauth2Token) {
      return `${this.oauth2Token.token_type} ${this.oauth2Token.access_token}`;
    }

    console.log('AuthProvider: getAuthorizationHeader void response.');
    return '';
  }

  getScopeBLInfo(): string | undefined {
    if (this.oauth2Token) {
      const payload: any = jwt.decode(this.oauth2Token.access_token);
      const scopePieces = payload.scopes
        .find((sc: string) => sc.startsWith(`${this.config.scope}:`))
        .split(':');
      if (scopePieces.length <= 3) {
        return '';
      } else {
        return scopePieces[3];
      }
    }
    return undefined;
  }
}

export const OAuth2Context = React.createContext<OAuth2Manager | null>(null);

export const OAuth2Provider = ({
  children,
  oauth2Manager,
  unsetOauth2Manager,
}: {
  children: React.ReactElement;
  oauth2Manager: OAuth2Manager;
  unsetOauth2Manager: () => void;
}) => {
  useEffect(() => {
    const interval = window.setInterval(async () => {
      console.log('Verifying session expiration...');
      oauth2Manager.checkAuth().catch(() => {
        console.log(
          'Impossible to refresh session, destroying global Oauth2Manager.'
        );
        unsetOauth2Manager();
      });
    }, 60000);

    return () => {
      window.clearInterval(interval);
    };
  });

  return (
    <OAuth2Context.Provider value={oauth2Manager}>
      {children}
    </OAuth2Context.Provider>
  );
};
