import React from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { Table } from '@material-ui/core';

const MasVendidosEUR = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => (
  <Paper style={{ padding: '1em 1em 3em 1em' }}>
    {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom={true}>
          {`${reportData.nombre}: ${reportTitle}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={true} align="right">
          <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
          {formattedStartDate} / {formattedEndDate}
        </Typography>
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <TableContainer
        component={({ children }) => <Grid item>{children}</Grid>}
      >
        <Table size="small" id="report-table">
          <TableHead>
            <TableRow>
              <TableCell>PLU</TableCell>
              <TableCell align="right">Nº VENTAS</TableCell>
              <TableCell align="right">CANTIDAD(KG)</TableCell>
              <TableCell align="right">IMPORTE(€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.detalle.map((row: any, idx: number) => (
              <>
                <TableRow key={idx}>
                  <TableCell
                    colSpan={4}
                    style={{ backgroundColor: 'lightgray' }}
                  >
                    {row.dpto + '  ' + row.descrip}
                  </TableCell>
                </TableRow>
                {row.plus.map((pluRes: any, idx2: number) => (
                  <>
                    <TableRow key={idx + ',' + idx2}>
                      <TableCell>{`${pluRes.plu} ${pluRes.descrip}`}</TableCell>
                      <TableCell align="right">{pluRes.ventas}</TableCell>
                      <TableCell align="right">{`${pluRes.cantidad}`}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }} align="right">
                        {pluRes.importe}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Paper>
);

export default React.memo(MasVendidosEUR);
