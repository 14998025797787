import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import merge from 'lodash.merge';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

import { Report } from '../reports/types';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

type ReportProps = {
  report: Report;
  shop: string;
  startDate: Date;
  endDate: Date;
};

const ReportComponent = ({ report, shop, startDate, endDate }: ReportProps) => {
  const classes = useStyles();

  const [reportData, setReportData] = useState();

  const variables = merge(
    {
      where: {
        id: shop,
      },
      whereTickets: {
        fecha: {
          gte: startDate,
          lte: endDate,
        },
      },
      // Trap to make appllo client refetch the query.
      reportName: report.name,
    },
    report.variables
  );
  console.log('Query variables', variables);
  const { loading, error, data } = useQuery(report.query, {
    variables,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    console.log('Requesting data...');
    setReportData(undefined);
  }, [loading]);

  useEffect(() => {
    if (data) {
      console.log('Processing data...', data);
      setReportData(report.postProcess(data));
    }
  }, [data]);

  const handleExportClick = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      html: '#report-table',
      useCss: false,
      styles: { fontSize: 8 },
    });
    doc.save('report.pdf');
  };

  if (!error && (loading || !reportData))
    return (
      <Paper style={{ padding: '1em' }}>
        <CircularProgress />
      </Paper>
    );

  if (error)
    return (
      <Alert severity="error">
        ERROR: {error.message}
        <br />
        <br />
        Intente recargar la página y si el problema persiste contacte con el
        administrador
      </Alert>
    );

  //console.log(data);
  //const reportData = report.postProcess(data);
  //console.log(reportData);
  //const ReportTemplate = report.template;

  return (
    <>
      <Tooltip title="Descargar PDF">
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="exportar"
          onClick={handleExportClick}
        >
          <SystemUpdateAltIcon />
        </Fab>
      </Tooltip>
      <report.template
        reportTitle={report.label}
        shop={shop}
        formattedStartDate={dayjs(startDate).format('DD/MM/YYYY')}
        formattedEndDate={dayjs(endDate).format('DD/MM/YYYY')}
        reportData={reportData}
      />
    </>
  );
};

export default ReportComponent;
