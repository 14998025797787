import React from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import dayjs from 'dayjs';
import TableFooter from '@material-ui/core/TableFooter';

import { Table } from '@material-ui/core';

const TicketsAnulados = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => (
  <Paper style={{ padding: '1em 1em 3em 1em' }}>
    {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom={true}>
          {`${reportData.nombre}: ${reportTitle}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={true} align="right">
          <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
          {formattedStartDate} / {formattedEndDate}
        </Typography>
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <TableContainer
        component={({ children }) => <Grid item>{children}</Grid>}
      >
        <Table size="small" id="report-table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>TICKET</TableCell>
              <TableCell></TableCell>
              <TableCell>VEND.</TableCell>
              <TableCell>PESO(Kg)</TableCell>
              <TableCell>IMPORTE(€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.detalle.map((row: any, idx: number) => (
              <>
                <TableRow style={{ backgroundColor: 'lightgray' }} key={idx}>
                  <TableCell style={{ fontWeight: 'bold' }} colSpan={5}>
                    {row.fecha}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    {row.importe}
                  </TableCell>
                </TableRow>
                {row.tickets.map((ticket: any, idx2: number) => (
                  <TableRow key={idx + ',' + idx2}>
                    <TableCell align="right">{ticket.hora}</TableCell>
                    <TableCell align="right">{ticket.ticket}</TableCell>
                    <TableCell>{ticket.tipo}</TableCell>
                    <TableCell>{ticket.vendedor}</TableCell>
                    <TableCell align="right">{ticket.peso}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      {ticket.importe}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
            <TableRow>
              <TableCell colSpan={6}>
                Nº Tickets: <strong>{reportData.total}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Paper>
);

export default React.memo(TicketsAnulados);
