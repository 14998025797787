import React, { useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import MainAppBar from './MainAppBar';
import ReportComponent from './Report';
import { reports } from '../reports';

import { OAuth2Context } from '../contexts/Auth';

import shops from '../shops.json';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MaterialLink color="inherit" href="https://material-ui.com/">
        Xauen Publicidad
      </MaterialLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const NoSelection = () => {
  return (
    <Grid container style={{ minHeight: '70vh' }} alignItems="center">
      <Grid item xs={12}>
        <Typography
          variant="h4"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          Sin selección de informe.
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
          Para mostrar un informe debe seleccionar la tienda, fecha de inicio,
          fecha de fin y hacer click en el tipo de informe deseado.
        </Typography>
      </Grid>
    </Grid>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    maxHeight: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  filterInput: {
    margin: theme.spacing(1),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  reportSelected: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: 'black',
    },
  },
}));

export default () => {
  const classes = useStyles();

  const oauth2Manager = useContext(OAuth2Context);

  const [authorizedShops] = useState<string[]>(() => {
    const shopsList: string[] = [];

    const blInfo = oauth2Manager?.getScopeBLInfo();
    if (typeof blInfo !== 'undefined') {
      const authorizedShopsRE = new RegExp(
        blInfo === '' ? '.*' : blInfo.split(',').join('|')
      );
      shops
        .filter((shop) => authorizedShopsRE.test(shop.id))
        .forEach((shop) => shopsList.push(shop.id));
    }

    return shopsList;
  });
  const [open, setOpen] = useState(true);
  const [shop, setShop] = useState(authorizedShops[0]);
  // const [startDate, setStartDate] = useState<Date | null>(
  //   dayjs().subtract(1, 'day').toDate()
  // );
  const [startDate, setStartDate] = useState(
    process.env.NODE_ENV !== 'production'
      ? new Date('2021-08-01')
      : dayjs().subtract(1, 'day').toDate()
  );
  const [endDate, setEndDate] = useState(
    process.env.NODE_ENV !== 'production'
      ? new Date('2021-08-10')
      : dayjs().toDate()
  );
  const [reportName, setReportName] = useState('');

  useEffect(() => {
    console.log('reportName:', reportName);
  }, [reportName]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <MainAppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <FormControl variant="filled" className={classes.filterInput}>
          <InputLabel>Tienda</InputLabel>
          <Select
            value={shop}
            onChange={(e) => setShop(e.target.value as string)}
            label="Tienda"
          >
            {shops
              .filter((shop) => authorizedShops.includes(shop.id))
              .map((shop) => (
                <MenuItem value={shop.id}>
                  {shop.id} - {shop.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <KeyboardDatePicker
          autoOk={true}
          value={startDate}
          onChange={(date) => setStartDate(date!.startOf('day').toDate())}
          variant="inline"
          inputVariant="filled"
          disableToolbar
          label="Fecha Inicio"
          format="DD/MM/YYYY"
          className={classes.filterInput}
        />
        <KeyboardDatePicker
          autoOk={true}
          value={endDate}
          onChange={(date) => setEndDate(date!.endOf('day').toDate())}
          minDate={dayjs(startDate).add(1, 'day')}
          variant="inline"
          inputVariant="filled"
          disableToolbar
          label="Fecha Fin"
          format="DD/MM/YYYY"
          className={classes.filterInput}
          minDateMessage="Fecha fin inferior a fecha inicio"
        />
        <Divider />
        <List dense={true}>
          {reports.map((report, index) => (
            <ListItem
              button
              key={index}
              onClick={() => setReportName(report.name)}
              className={
                report.name === reportName ? classes.reportSelected : undefined
              }
            >
              <ListItemText primary={report.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {reportName && shop && startDate && endDate ? (
                <ReportComponent
                  report={reports.find((rpt) => rpt.name === reportName)!}
                  shop={shop}
                  startDate={startDate}
                  endDate={endDate}
                />
              ) : (
                <NoSelection />
              )}
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
