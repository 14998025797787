import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: '.9em',
      },
    },
  },
});

export default theme;
