import { gql } from '@apollo/client';
import dayjs from 'dayjs';

import VentasDiarias from './templates/VentasDiarias';
import VentasMensuales from './templates/VentasMensuales';
import TicketsDetalle from './templates/TicketsDetalle';
import VentasResumen from './templates/VentasResumen';
import TicketsAnulados from './templates/TicketsAnulados';
import TicketsCliente from './templates/TicketsCliente';
import VentasPLU from './templates/VentasPLU';
import VentasPLUHora from './templates/VentasPLUHora';
import VentasHora from './templates/VentasHora';
import Arqueo from './templates/Arqueo';
import MasVendidosEUR from './templates/MasVendidosEUR';
import MasVendidosKg from './templates/MasVendidosKg';
import MasVendidosUn from './templates/MasVendidosUn';
import VentasDpto from './templates/VentasDpto';
import VentasBalanza from './templates/VentasBalanza';
import VentasVendedor from './templates/VentasVendedor';
import VentasVxPLU from './templates/VentasVxPLU';
import VentasFamilia from './templates/VentasFamilia';

export * from './types';

export const reports = [
  //////////////////// ventasDiarias
  {
    name: 'ventasDiarias',
    label: 'Ventas Diarias',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets) {
            fecha
            ticket
            peso
            importe
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasDiarias,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      const total = {
        peso: 0.0,
        importe: 0.0,
        tickets: 0,
        ventaMedia: 0.0,
      };
      for (const ticketObj of data.tienda.tickets) {
        const { fecha: fechaHora, peso, importe } = ticketObj;
        const fecha = dayjs(fechaHora).format('DD/MM/YYYY');
        if (!detalle[fecha])
          detalle[fecha] = { tickets: 0, importe: 0.0, peso: 0.0 };
        detalle[fecha].tickets++;
        detalle[fecha].importe += importe;
        detalle[fecha].peso += peso;
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => {
          total.tickets += detalle[fecha].tickets;
          total.importe += detalle[fecha].importe;
          total.peso += detalle[fecha].peso;
          return {
            fecha,
            tickets: detalle[fecha].tickets,
            importe: detalle[fecha].importe.toFixed(2),
            peso: detalle[fecha].peso.toFixed(3),
            ventaMedia: (
              detalle[fecha].importe / detalle[fecha].tickets
            ).toFixed(2),
          };
        }),
        total: {
          tickets: total.tickets,
          importe: total.importe.toFixed(2),
          peso: total.peso.toFixed(3),
          ventaMedia: total.tickets
            ? (total.importe / total.tickets).toFixed(2)
            : '0.00',
        },
      };
    },
  },
  //////////////////// ventasMensuales
  {
    name: 'ventasMensuales',
    label: 'Ventas Mensuales',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets) {
            fecha
            ticket
            peso
            importe
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasMensuales,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      const total = {
        peso: 0.0,
        importe: 0.0,
        tickets: 0,
        ventaMedia: 0.0,
      };
      for (const ticketObj of data.tienda.tickets) {
        const { fecha: fechaHora, peso, importe } = ticketObj;
        const fecha = dayjs(fechaHora).format('MM/YYYY');
        if (!detalle[fecha])
          detalle[fecha] = { tickets: 0, importe: 0.0, peso: 0.0 };
        detalle[fecha].tickets++;
        detalle[fecha].importe += importe;
        detalle[fecha].peso += peso;
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => {
          total.tickets += detalle[fecha].tickets;
          total.importe += detalle[fecha].importe;
          total.peso += detalle[fecha].peso;
          return {
            fecha,
            tickets: detalle[fecha].tickets,
            importe: detalle[fecha].importe.toFixed(2),
            peso: detalle[fecha].peso.toFixed(3),
            ventaMedia: (
              detalle[fecha].importe / detalle[fecha].tickets
            ).toFixed(2),
          };
        }),
        total: {
          tickets: total.tickets,
          importe: total.importe.toFixed(2),
          peso: total.peso.toFixed(3),
          ventaMedia: total.tickets
            ? (total.importe / total.tickets).toFixed(2)
            : '0.00',
        },
      };
    },
  },
  //////////////////// ticketsDetalle
  {
    name: 'ticketsDetalle',
    label: 'Tickets (Detalle)',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            importe
            vendedor
            tipo
            caja
            detalle {
              dpto
              plu
              descrip
              importe
              peso
              unidades
              tipo
              dcto
            }
          }
        }
      }
    `,
    variables: { orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }] },
    template: TicketsDetalle,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: { [ticket: string]: any } } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha]) detalle[strFecha] = {};
        if (!detalle[strFecha][ticketObj.ticket])
          detalle[strFecha][ticketObj.ticket] = {
            hora: dayjs(ticketObj.fecha).format('HH:mm'),
            vendedor: ticketObj.vendedor,
            importe: ticketObj.importe.toFixed(2),
            lineas: [],
          };
        for (const linea of ticketObj.detalle) {
          detalle[strFecha][ticketObj.ticket].lineas.push({
            ...linea,
            importe: linea.importe.toFixed(2),
            peso: linea.peso.toFixed(3),
            dcto: linea.dcto.toFixed(2),
          });
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          tickets: Object.keys(detalle[fecha]).map((ticket) => ({
            ticket,
            ...detalle[fecha][ticket],
          })),
        })),
      };
    },
  },
  //////////////////// ventasResumen
  {
    name: 'ventasResumen',
    label: 'Ventas (Resumen)',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasResumen,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha])
          detalle[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[strFecha].importe += ticketObj.importe;
        detalle[strFecha].tickets.push({
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          dcto: ticketObj.dcto ? ticketObj.dcto.toFixed(2) : ticketObj.dcto,
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          importe: detalle[fecha].importe.toFixed(2),
          tickets: detalle[fecha].tickets,
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ticketsNulos
  {
    name: 'ticketsNulos',
    label: 'Tickets Nulos',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'N',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: TicketsAnulados,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha])
          detalle[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[strFecha].importe += ticketObj.importe;
        detalle[strFecha].tickets.push({
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          dcto: ticketObj.dcto ? ticketObj.dcto.toFixed(2) : ticketObj.dcto,
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          importe: detalle[fecha].importe.toFixed(2),
          tickets: detalle[fecha].tickets,
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ticketsAnulados
  {
    name: 'ticketsAnulados',
    label: 'Tickets Anulados',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'A',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: TicketsAnulados,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha])
          detalle[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[strFecha].importe += ticketObj.importe;
        detalle[strFecha].tickets.push({
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          dcto: ticketObj.dcto ? ticketObj.dcto.toFixed(2) : ticketObj.dcto,
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          importe: detalle[fecha].importe.toFixed(2),
          tickets: detalle[fecha].tickets,
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ticketsAnuladosPC
  {
    name: 'ticketsAnuladosPC',
    label: 'Tickets Anulados PC',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'P',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: TicketsAnulados,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha])
          detalle[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[strFecha].importe += ticketObj.importe;
        detalle[strFecha].tickets.push({
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          dcto: ticketObj.dcto ? ticketObj.dcto.toFixed(2) : ticketObj.dcto,
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          importe: detalle[fecha].importe.toFixed(2),
          tickets: detalle[fecha].tickets,
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ticketsPerdidosResumen
  {
    name: 'ticketsPerdidosResumen',
    label: 'T. Perdidos (Resumen)',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
        caja: {
          equals: 0,
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: TicketsAnulados,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: any } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha])
          detalle[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[strFecha].importe += ticketObj.importe;
        detalle[strFecha].tickets.push({
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          dcto: ticketObj.dcto ? ticketObj.dcto.toFixed(2) : ticketObj.dcto,
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          importe: detalle[fecha].importe.toFixed(2),
          tickets: detalle[fecha].tickets,
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ticketsPerdidosDetalle
  {
    name: 'ticketsPerdidosDetalle',
    label: 'T. Perdidos (Detalle)',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            importe
            vendedor
            tipo
            caja
            detalle {
              dpto
              plu
              descrip
              importe
              peso
              unidades
              tipo
              dcto
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
        caja: {
          equals: 0,
        },
      },
    },
    template: TicketsDetalle,
    postProcess: (data: any) => {
      const detalle: { [fecha: string]: { [ticket: string]: any } } = {};
      for (const ticketObj of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[strFecha]) detalle[strFecha] = {};
        if (!detalle[strFecha][ticketObj.ticket])
          detalle[strFecha][ticketObj.ticket] = {
            hora: dayjs(ticketObj.fecha).format('HH:mm'),
            vendedor: ticketObj.vendedor,
            importe: ticketObj.importe.toFixed(2),
            lineas: [],
          };
        for (const linea of ticketObj.detalle) {
          detalle[strFecha][ticketObj.ticket].lineas.push({
            ...linea,
            importe: linea.importe.toFixed(2),
            peso: linea.peso.toFixed(3),
            dcto: linea.dcto.toFixed(2),
          });
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((fecha) => ({
          fecha,
          tickets: Object.keys(detalle[fecha]).map((ticket) => ({
            ticket,
            ...detalle[fecha][ticket],
          })),
        })),
      };
    },
  },
  //////////////////// ticketsCliente
  {
    name: 'ticketsCliente',
    label: 'Tickets cliente',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            ticket
            balanza
            vendedor
            peso
            importe
            tipo
            cliente
            fpago
            dcto
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ cliente: 'asc' }, { fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: TicketsCliente,
    postProcess: (data: any) => {
      const detalle: { [cliente: string]: { [fecha: string]: any } } = {};
      for (const { cliente, ...ticketObj } of data.tienda.tickets) {
        const strFecha = dayjs(ticketObj.fecha).format('DD/MM/YYYY');
        if (!detalle[cliente]) detalle[cliente] = { importe: 0.0, fechas: {} };
        if (!detalle[cliente].fechas[strFecha])
          detalle[cliente].fechas[strFecha] = {
            importe: 0.0,
            tickets: [],
          };
        detalle[cliente].importe += ticketObj.importe;
        detalle[cliente].fechas[strFecha].importe += ticketObj.importe;
        detalle[cliente].fechas[strFecha].tickets.push({
          cliente,
          ...ticketObj,
          hora: dayjs(ticketObj.fecha).format('HH:mm'),
          peso: ticketObj.peso.toFixed(3),
          importe: ticketObj.importe.toFixed(2),
        });
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((cliente) => ({
          cliente,
          importe: detalle[cliente].importe.toFixed(2),
          fechas: Object.keys(detalle[cliente].fechas).map((fecha) => ({
            fecha,
            importe: detalle[cliente].fechas[fecha].importe.toFixed(2),
            tickets: detalle[cliente].fechas[fecha].tickets,
          })),
        })),
        total: data.tienda.tickets.length,
      };
    },
  },
  //////////////////// ventasPLU
  {
    name: 'ventasPLU',
    label: 'Ventas por PLU',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          plus {
            dpto
            plu
            familia
            descrip
            precio1
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle {
              fecha
              dpto
              plu
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasPLU,
    postProcess: (data: any) => {
      const plus: { [dpto: string]: { [plu: string]: any } } = {};
      const detalle: { [dpto: string]: any } = {};
      // Index PLUs
      for (const { dpto, plu, ...pluObj } of data.tienda.plus) {
        if (!plus[dpto]) plus[dpto] = {};
        plus[dpto][plu] = { dpto, plu, ...pluObj };
      }
      console.log('PLUs', plus);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const { dpto, plu, ...linea } of lineas) {
          if (!detalle[dpto]) detalle[dpto] = {};
          if (!detalle[dpto][plu])
            detalle[dpto][plu] = {
              ventas: 0,
              cantidad: 0.0,
              unidades: linea.unidades,
            };
          detalle[dpto][plu].ventas++;
          detalle[dpto][plu].cantidad += linea.peso;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          plus: Object.keys(detalle[dpto])
            .sort()
            .map((plu) => ({
              plu,
              descrip: plus[dpto][plu].descrip,
              ventas: detalle[dpto][plu].ventas,
              precio: plus[dpto][plu].precio1.toFixed(2),
              cantidad: detalle[dpto][plu].cantidad.toFixed(3),
              importe: (
                detalle[dpto][plu].cantidad * plus[dpto][plu].precio1
              ).toFixed(2),
              importeMedio: (
                (detalle[dpto][plu].cantidad * plus[dpto][plu].precio1) /
                detalle[dpto][plu].ventas
              ).toFixed(2),
              cantidadMedia: (
                detalle[dpto][plu].cantidad / detalle[dpto][plu].ventas
              ).toFixed(3),
              unidades: detalle[dpto][plu].unidades,
            })),
        })),
      };
    },
  },
  //////////////////// ventasPLUHora
  {
    name: 'ventasPLUHora',
    label: 'Ventas por PLU/Hora',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle {
              fecha
              dpto
              plu
              descrip
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasPLUHora,
    postProcess: (data: any) => {
      const detalle: { [plu: string]: any } = {};

      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const {
          fecha,
          dpto,
          plu,
          descrip,
          peso,
          importe,
          unidades,
        } of lineas) {
          const key = `${dpto}${plu}`;
          if (!detalle[key])
            detalle[key] = {
              dpto,
              plu,
              descrip,
              unidades,
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
              fechas: {},
            };
          const dayKey = dayjs(fecha).format('DD/MM/YYYY');
          const hour = dayjs(fecha).format('HH');
          const hourKey = `${hour} a ${String(Number(hour) + 1).padStart(
            2,
            '0'
          )}`;
          if (!detalle[key].fechas[dayKey])
            detalle[key].fechas[dayKey] = {
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
              horas: {},
            };
          if (!detalle[key].fechas[dayKey].horas[hourKey])
            detalle[key].fechas[dayKey].horas[hourKey] = {
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
            };
          detalle[key].ventas++;
          detalle[key].cantidad += peso;
          detalle[key].importe += importe;
          detalle[key].fechas[dayKey].ventas++;
          detalle[key].fechas[dayKey].cantidad += peso;
          detalle[key].fechas[dayKey].importe += importe;
          detalle[key].fechas[dayKey].horas[hourKey].ventas++;
          detalle[key].fechas[dayKey].horas[hourKey].cantidad += peso;
          detalle[key].fechas[dayKey].horas[hourKey].importe += importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle)
          .sort()
          .map((dptoPLU) => ({
            dpto: detalle[dptoPLU].dpto,
            plu: detalle[dptoPLU].plu,
            descrip: detalle[dptoPLU].descrip,
            ventas: detalle[dptoPLU].ventas,
            cantidad: detalle[dptoPLU].cantidad.toFixed(3),
            importe: detalle[dptoPLU].importe.toFixed(2),
            unidades: detalle[dptoPLU].unidades,
            dias: Object.keys(detalle[dptoPLU].fechas).map((dia) => ({
              dia,
              ventas: detalle[dptoPLU].fechas[dia].ventas,
              cantidad: detalle[dptoPLU].fechas[dia].cantidad.toFixed(3),
              importe: detalle[dptoPLU].fechas[dia].importe.toFixed(2),
              horas: Object.keys(detalle[dptoPLU].fechas[dia].horas)
                .sort()
                .map((hora) => ({
                  hora,
                  ventas: detalle[dptoPLU].fechas[dia].horas[hora].ventas,
                  cantidad:
                    detalle[dptoPLU].fechas[dia].horas[hora].cantidad.toFixed(
                      3
                    ),
                  importe:
                    detalle[dptoPLU].fechas[dia].horas[hora].importe.toFixed(2),
                })),
            })),
          })),
      };
    },
  },
  //////////////////// ventasHoras
  {
    name: 'ventasHoras',
    label: 'Ventas por horas',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            peso
            importe
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasHora,
    postProcess: (data: any) => {
      const detalle: { [plu: string]: any } = {};

      // Iterate over ticket details
      for (const { fecha, peso, importe } of data.tienda.tickets) {
        const dayKey = dayjs(fecha).format('DD/MM/YYYY');
        const hour = dayjs(fecha).format('HH');
        const hourKey = `${hour} a ${String(Number(hour) + 1).padStart(
          2,
          '0'
        )}`;
        if (!detalle[dayKey])
          detalle[dayKey] = {
            ventas: 0,
            cantidad: 0.0,
            importe: 0.0,
            horas: {},
          };
        if (!detalle[dayKey].horas[hourKey])
          detalle[dayKey].horas[hourKey] = {
            ventas: 0,
            cantidad: 0.0,
            importe: 0.0,
          };
        detalle[dayKey].ventas++;
        detalle[dayKey].cantidad += peso;
        detalle[dayKey].importe += importe;
        detalle[dayKey].horas[hourKey].ventas++;
        detalle[dayKey].horas[hourKey].cantidad += peso;
        detalle[dayKey].horas[hourKey].importe += importe;
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle)
          .sort()
          .map((dia) => ({
            dia,
            ventas: detalle[dia].ventas,
            cantidad: detalle[dia].cantidad.toFixed(3),
            importe: detalle[dia].importe.toFixed(2),
            horas: Object.keys(detalle[dia].horas)
              .sort()
              .map((hora) => ({
                hora,
                ventas: detalle[dia].horas[hora].ventas,
                cantidad: detalle[dia].horas[hora].cantidad.toFixed(3),
                importe: detalle[dia].horas[hora].importe.toFixed(2),
              })),
          })),
      };
    },
  },
  //////////////////// arqueo
  {
    name: 'arqueo',
    label: 'Arqueo',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets) {
            fecha
            importe
            fpago
            tipo
          }
        }
      }
    `,
    variables: {
      whereTickets: {},
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: Arqueo,
    postProcess: (data: any) => {
      const detalle: { [dia: string]: any } = {};
      const total = {
        pagoTarjeta: 0.0,
        pagoVarios: 0.0,
        importeC: 0.0,
        importeD: 0.0,
        importeR: 0.0,
        difArqueo: 0.0,
        sobraFalta: '',
      };
      for (const { fecha, importe, fpago, tipo } of data.tienda.tickets) {
        const dia = dayjs(fecha).format('DD/MM/YYYY');
        if (!detalle[dia])
          detalle[dia] = {
            pagoTarjeta: 0.0,
            pagoVarios: 0.0,
            importeC: 0.0,
            importeD: 0.0,
            importeR: 0.0,
          };
        if (tipo === 'C') {
          switch (fpago) {
            case '0':
              detalle[dia].importeC += importe;
              break;
            case '1':
              detalle[dia].pagoTarjeta += importe;
              break;
            default:
              detalle[dia].pagoVarios += importe;
          }
        } else if (tipo === 'D') {
          detalle[dia].importeD += importe;
        } else if (tipo === 'R') {
          detalle[dia].importeR += importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dia) => {
          total.pagoTarjeta += detalle[dia].pagoTarjeta;
          total.pagoVarios += detalle[dia].pagoVarios;
          total.importeC += detalle[dia].importeC;
          total.importeD += detalle[dia].importeD;
          total.importeR += detalle[dia].importeR;
          const difArqueo =
            detalle[dia].importeR -
            detalle[dia].importeC -
            detalle[dia].importeD;
          total.difArqueo += difArqueo;
          total.sobraFalta =
            total.difArqueo > 0 ? 'SOBRA' : total.difArqueo < 0 ? 'FALTA' : '';
          return {
            dia,
            pagoTarjeta: detalle[dia].pagoTarjeta.toFixed(2),
            pagoVarios: detalle[dia].pagoVarios.toFixed(2),
            importeC: detalle[dia].importeC.toFixed(2),
            importeD: detalle[dia].importeD.toFixed(2),
            importeR: detalle[dia].importeR.toFixed(2),
            difArqueo: difArqueo.toFixed(2),
            sobraFalta: difArqueo > 0 ? 'SOBRA' : difArqueo < 0 ? 'FALTA' : '',
          };
        }),
        total: {
          pagoTarjeta: total.pagoTarjeta.toFixed(2),
          pagoVarios: total.pagoVarios.toFixed(2),
          importeC: total.importeC.toFixed(2),
          importeD: total.importeD.toFixed(2),
          importeR: total.importeR.toFixed(2),
          difArqueo: total.difArqueo.toFixed(2),
          sobraFalta:
            total.difArqueo > 0 ? 'SOBRA' : total.difArqueo < 0 ? 'FALTA' : '',
        },
      };
    },
  },
  //////////////////// masVendidosEUR
  {
    name: 'masVendidosEUR',
    label: 'Más vendidos en €',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          dptos {
            dpto
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle {
              fecha
              dpto
              plu
              descrip
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: MasVendidosEUR,
    postProcess: (data: any) => {
      const dptos: { [dpto: string]: string } = {};
      const detalle: { [dpto: string]: any } = {};
      // Index dptos
      for (const { dpto, descrip } of data.tienda.dptos) {
        dptos[dpto] = descrip;
      }
      console.log('DPTOs', dptos);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const {
          fecha,
          dpto,
          plu,
          descrip,
          importe,
          peso,
          unidades,
        } of lineas) {
          if (!detalle[dpto]) detalle[dpto] = {};
          if (!detalle[dpto][plu])
            detalle[dpto][plu] = {
              descrip,
              unidades,
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
            };
          detalle[dpto][plu].ventas++;
          detalle[dpto][plu].cantidad += peso;
          detalle[dpto][plu].importe += importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          descrip: dptos[dpto],
          plus: Object.keys(detalle[dpto])
            .map((plu) => ({
              plu,
              descrip: detalle[dpto][plu].descrip,
              ventas: detalle[dpto][plu].ventas,
              importe: detalle[dpto][plu].importe.toFixed(2),
              cantidad: detalle[dpto][plu].cantidad.toFixed(3),
              unidades: detalle[dpto][plu].unidades,
            }))
            .sort((a, b) => b.importe - a.importe),
        })),
      };
    },
  },
  //////////////////// masVendidosKg
  {
    name: 'masVendidosKg',
    label: 'Más vendidos en Kg',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $whereDetalle: DetalleTicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          dptos {
            dpto
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle(where: $whereDetalle) {
              fecha
              dpto
              plu
              descrip
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      whereDetalle: {
        unidades: {
          equals: 'Kg',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: MasVendidosKg,
    postProcess: (data: any) => {
      const dptos: { [dpto: string]: string } = {};
      const detalle: { [dpto: string]: any } = {};
      // Index dptos
      for (const { dpto, descrip } of data.tienda.dptos) {
        dptos[dpto] = descrip;
      }
      console.log('DPTOs', dptos);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const {
          fecha,
          dpto,
          plu,
          descrip,
          importe,
          peso,
          unidades,
        } of lineas) {
          if (!detalle[dpto]) detalle[dpto] = {};
          if (!detalle[dpto][plu])
            detalle[dpto][plu] = {
              descrip,
              unidades,
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
            };
          detalle[dpto][plu].ventas++;
          detalle[dpto][plu].cantidad += peso;
          detalle[dpto][plu].importe += importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          descrip: dptos[dpto],
          plus: Object.keys(detalle[dpto])
            .map((plu) => ({
              plu,
              descrip: detalle[dpto][plu].descrip,
              ventas: detalle[dpto][plu].ventas,
              importe: detalle[dpto][plu].importe.toFixed(2),
              cantidad: detalle[dpto][plu].cantidad.toFixed(3),
              unidades: detalle[dpto][plu].unidades,
            }))
            .sort((a, b) => b.cantidad - a.cantidad),
        })),
      };
    },
  },
  //////////////////// masVendidosUn
  {
    name: 'masVendidosUn',
    label: 'Más vendidos en Un',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $whereDetalle: DetalleTicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          dptos {
            dpto
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle(where: $whereDetalle) {
              fecha
              dpto
              plu
              descrip
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      whereDetalle: {
        unidades: {
          equals: 'Un',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: MasVendidosUn,
    postProcess: (data: any) => {
      const dptos: { [dpto: string]: string } = {};
      const detalle: { [dpto: string]: any } = {};
      // Index dptos
      for (const { dpto, descrip } of data.tienda.dptos) {
        dptos[dpto] = descrip;
      }
      console.log('DPTOs', dptos);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const {
          fecha,
          dpto,
          plu,
          descrip,
          importe,
          peso,
          unidades,
        } of lineas) {
          if (!detalle[dpto]) detalle[dpto] = {};
          if (!detalle[dpto][plu])
            detalle[dpto][plu] = {
              descrip,
              unidades,
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
            };
          detalle[dpto][plu].ventas++;
          detalle[dpto][plu].cantidad += peso;
          detalle[dpto][plu].importe += importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          descrip: dptos[dpto],
          plus: Object.keys(detalle[dpto])
            .map((plu) => ({
              plu,
              descrip: detalle[dpto][plu].descrip,
              ventas: detalle[dpto][plu].ventas,
              importe: detalle[dpto][plu].importe.toFixed(2),
              cantidad: detalle[dpto][plu].cantidad.toFixed(3),
              unidades: detalle[dpto][plu].unidades,
            }))
            .sort((a, b) => b.cantidad - a.cantidad),
        })),
      };
    },
  },
  //////////////////// ventasDpto
  {
    name: 'ventasDpto',
    label: 'Ventas por dpto.',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          dptos {
            dpto
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle {
              fecha
              dpto
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasDpto,
    postProcess: (data: any) => {
      const dptos: { [dpto: string]: string } = {};
      const detalle: { [dpto: string]: any } = {};
      const total = {
        cantidad: 0.0,
        importe: 0.0,
      };
      for (const { dpto, descrip } of data.tienda.dptos) {
        dptos[dpto] = descrip;
      }
      console.log('DPTOs', dptos);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const { fecha, dpto, importe, peso, unidades } of lineas) {
          if (!detalle[dpto])
            detalle[dpto] = {
              cantidad: 0.0,
              importe: 0.0,
            };
          if (unidades === 'Kg') {
            detalle[dpto].cantidad += peso;
            detalle[dpto].importe += importe;
            total.cantidad += peso;
            total.importe += importe;
          }
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          descrip: dptos[dpto],
          importe: detalle[dpto].importe.toFixed(2),
          cantidad: detalle[dpto].cantidad.toFixed(3),
        })),
        total: {
          importe: total.importe.toFixed(2),
          cantidad: total.cantidad.toFixed(3),
        },
      };
    },
  },
  //////////////////// ventasBalanza
  {
    name: 'ventasBalanza',
    label: 'Ventas por balanza',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            balanza
            importe
            peso
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasBalanza,
    postProcess: (data: any) => {
      const detalle: { [balanza: string]: { [dia: string]: any } } = {};
      const total = {
        ventas: 0,
        cantidad: 0.0,
        importe: 0.0,
      };
      // Iterate over ticket details
      for (const { fecha, balanza, importe, peso } of data.tienda.tickets) {
        if (!detalle[balanza])
          detalle[balanza] = {
            ventas: 0,
            cantidad: 0.0,
            importe: 0.0,
            dias: {},
          };
        const dia = dayjs(fecha).format('DD/MM/YYYY');
        if (!detalle[balanza].dias[dia])
          detalle[balanza].dias[dia] = {
            ventas: 0,
            cantidad: 0.0,
            importe: 0.0,
          };
        detalle[balanza].ventas++;
        detalle[balanza].cantidad += peso;
        detalle[balanza].importe += importe;
        detalle[balanza].dias[dia].ventas++;
        detalle[balanza].dias[dia].cantidad += peso;
        detalle[balanza].dias[dia].importe += importe;
        total.ventas++;
        total.cantidad += peso;
        total.importe += importe;
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((balanza) => ({
          balanza,
          ventas: detalle[balanza].ventas,
          importe: detalle[balanza].importe.toFixed(2),
          cantidad: detalle[balanza].cantidad.toFixed(3),
          ticketMedio: (
            detalle[balanza].importe / detalle[balanza].ventas
          ).toFixed(2),
          dias: Object.keys(detalle[balanza].dias).map((dia) => ({
            dia,
            ventas: detalle[balanza].dias[dia].ventas,
            importe: detalle[balanza].dias[dia].importe.toFixed(2),
            cantidad: detalle[balanza].dias[dia].cantidad.toFixed(3),
            ticketMedio: (
              detalle[balanza].dias[dia].importe /
              detalle[balanza].dias[dia].ventas
            ).toFixed(2),
          })),
        })),
        total: {
          ventas: total.ventas,
          importe: total.importe.toFixed(2),
          cantidad: total.cantidad.toFixed(3),
        },
      };
    },
  },
  //////////////////// ventasVendedor
  {
    name: 'ventasVendedor',
    label: 'Ventas por vendedor',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          vendedores {
            vendedor
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            fecha
            tipo
            importe
            peso
            vendedor
          }
        }
      }
    `,
    variables: {
      whereTickets: {},
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasVendedor,
    postProcess: (data: any) => {
      const vendedores: { [vendedor: string]: string } = {};
      const detalle: { [vendedor: string]: any } = {};
      const total: { [ticket: string]: number } = {
        ticketsC: 0,
        ticketsA: 0,
        ticketsN: 0,
        ticketsP: 0,
        ticketsR: 0,
        ticketsI: 0,
        ticketsM: 0,
      };
      for (const { vendedor, descrip } of data.tienda.vendedores) {
        vendedores[vendedor] = descrip;
      }
      console.log('Vendedores', vendedores);
      // Iterate over ticket details
      for (const { fecha, vendedor, importe, peso, tipo } of data.tienda
        .tickets) {
        if (!detalle[vendedor])
          detalle[vendedor] = {
            cantidad: 0.0,
            importe: 0.0,
            ticketsC: 0,
            ticketsA: 0,
            ticketsN: 0,
            ticketsP: 0,
            ticketsR: 0,
            ticketsI: 0,
            ticketsM: 0,
          };
        detalle[vendedor].cantidad += peso;
        detalle[vendedor].importe += importe;
        detalle[vendedor]['tickets' + tipo]++;
        total['tickets' + tipo]++;
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle)
          .sort()
          .map((vendedor) => ({
            vendedor,
            descrip: vendedores[vendedor],
            ...detalle[vendedor],
            importe: detalle[vendedor].importe.toFixed(2),
            cantidad: detalle[vendedor].cantidad.toFixed(3),
          })),
        total,
      };
    },
  },
  //////////////////// ventasVxPLU
  {
    name: 'ventasVxPLU',
    label: 'Ventas por Vx/PLU',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          vendedores {
            vendedor
            descrip
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            vendedor
            detalle {
              fecha
              dpto
              plu
              descrip
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasVxPLU,
    postProcess: (data: any) => {
      const vendedores: { [vendedor: string]: string } = {};
      const detalle: { [vendedor: string]: any } = {};
      for (const { vendedor, descrip } of data.tienda.vendedores) {
        vendedores[vendedor] = descrip;
      }
      // Iterate over ticket details
      for (const { detalle: lineas, vendedor } of data.tienda.tickets) {
        for (const {
          fecha,
          dpto,
          plu,
          descrip,
          peso,
          importe,
          unidades,
        } of lineas) {
          if (!detalle[vendedor])
            detalle[vendedor] = {
              ventas: 0,
              importe: 0.0,
              plus: {},
            };
          const key = `${dpto}${plu}`;
          if (!detalle[vendedor].plus[key])
            detalle[vendedor].plus[key] = {
              dpto,
              plu,
              descrip,
              unidades,
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
              fechas: {},
            };
          const dayKey = dayjs(fecha).format('DD/MM/YYYY');
          const hour = dayjs(fecha).format('HH');
          const hourKey = `${hour} a ${String(Number(hour) + 1).padStart(
            2,
            '0'
          )}`;
          if (!detalle[vendedor].plus[key].fechas[dayKey])
            detalle[vendedor].plus[key].fechas[dayKey] = {
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
              horas: {},
            };
          if (!detalle[vendedor].plus[key].fechas[dayKey].horas[hourKey])
            detalle[vendedor].plus[key].fechas[dayKey].horas[hourKey] = {
              ventas: 0,
              cantidad: 0.0,
              importe: 0.0,
            };
          detalle[vendedor].ventas++;
          detalle[vendedor].importe += importe;
          detalle[vendedor].plus[key].ventas++;
          detalle[vendedor].plus[key].cantidad += peso;
          detalle[vendedor].plus[key].importe += importe;
          detalle[vendedor].plus[key].fechas[dayKey].ventas++;
          detalle[vendedor].plus[key].fechas[dayKey].cantidad += peso;
          detalle[vendedor].plus[key].fechas[dayKey].importe += importe;
          detalle[vendedor].plus[key].fechas[dayKey].horas[hourKey].ventas++;
          detalle[vendedor].plus[key].fechas[dayKey].horas[hourKey].cantidad +=
            peso;
          detalle[vendedor].plus[key].fechas[dayKey].horas[hourKey].importe +=
            importe;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle)
          .sort()
          .map((vendedor) => ({
            vendedor,
            descrip: vendedores[vendedor],
            ventas: detalle[vendedor].ventas,
            importe: detalle[vendedor].importe.toFixed(2),
            plus: Object.keys(detalle[vendedor].plus)
              .sort()
              .map((dptoPLU) => ({
                dpto: detalle[vendedor].plus[dptoPLU].dpto,
                plu: detalle[vendedor].plus[dptoPLU].plu,
                descrip: detalle[vendedor].plus[dptoPLU].descrip,
                ventas: detalle[vendedor].plus[dptoPLU].ventas,
                cantidad: detalle[vendedor].plus[dptoPLU].cantidad.toFixed(3),
                importe: detalle[vendedor].plus[dptoPLU].importe.toFixed(2),
                unidades: detalle[vendedor].plus[dptoPLU].unidades,
                dias: Object.keys(detalle[vendedor].plus[dptoPLU].fechas).map(
                  (dia) => ({
                    dia,
                    ventas: detalle[vendedor].plus[dptoPLU].fechas[dia].ventas,
                    cantidad:
                      detalle[vendedor].plus[dptoPLU].fechas[
                        dia
                      ].cantidad.toFixed(3),
                    importe:
                      detalle[vendedor].plus[dptoPLU].fechas[
                        dia
                      ].importe.toFixed(2),
                    horas: Object.keys(
                      detalle[vendedor].plus[dptoPLU].fechas[dia].horas
                    )
                      .sort()
                      .map((hora) => ({
                        hora,
                        ventas:
                          detalle[vendedor].plus[dptoPLU].fechas[dia].horas[
                            hora
                          ].ventas,
                        cantidad:
                          detalle[vendedor].plus[dptoPLU].fechas[dia].horas[
                            hora
                          ].cantidad.toFixed(3),
                        importe:
                          detalle[vendedor].plus[dptoPLU].fechas[dia].horas[
                            hora
                          ].importe.toFixed(2),
                      })),
                  })
                ),
              })),
          })),
      };
    },
  },
  //////////////////// ventasFamilia
  {
    name: 'ventasFamilia',
    label: 'Ventas por familia',
    query: gql`
      query tienda(
        $where: TiendaWhereUniqueInput!
        $whereTickets: TicketWhereInput
        $orderByTickets: [TicketOrderByInput!]
      ) {
        tienda(where: $where) {
          id
          nombre
          createdAt
          updatedAt
          plus {
            dpto
            plu
            familia
            descrip
            precio1
          }
          tickets(where: $whereTickets, orderBy: $orderByTickets) {
            detalle {
              fecha
              dpto
              plu
              importe
              peso
              unidades
            }
          }
        }
      }
    `,
    variables: {
      whereTickets: {
        tipo: {
          equals: 'C',
        },
      },
      orderByTickets: [{ fecha: 'asc' }, { ticket: 'asc' }],
    },
    template: VentasFamilia,
    postProcess: (data: any) => {
      const plus: { [dpto: string]: { [plu: string]: any } } = {};
      const detalle: { [dpto: string]: any } = {};
      const total = {
        ventas: 0,
        cantidadUd: 0.0,
        cantidadKg: 0.0,
        importe: 0.0,
      };
      // Index PLUs
      for (const { dpto, plu, ...pluObj } of data.tienda.plus) {
        if (!plus[dpto]) plus[dpto] = {};
        plus[dpto][plu] = { dpto, plu, ...pluObj };
      }
      console.log('PLUs', plus);
      // Iterate over ticket details
      for (const { detalle: lineas } of data.tienda.tickets) {
        for (const { dpto, plu, peso, importe, unidades } of lineas) {
          if (!detalle[dpto])
            detalle[dpto] = {
              ventas: 0,
              cantidadUd: 0.0,
              cantidadKg: 0.0,
              importe: 0.0,
              familias: {},
            };
          const familia = plus[dpto][plu].familia;
          if (!detalle[dpto].familias[familia])
            detalle[dpto].familias[familia] = {
              ventas: 0,
              cantidadUd: 0.0,
              cantidadKg: 0.0,
              importe: 0.0,
            };
          total.ventas++;
          detalle[dpto].ventas++;
          detalle[dpto].familias[familia].ventas++;
          total.importe += importe;
          detalle[dpto].importe += importe;
          detalle[dpto].familias[familia].importe += importe;
          const cantidadProp = unidades === 'Kg' ? 'cantidadKg' : 'cantidadUd';
          total[cantidadProp] += peso;
          detalle[dpto][cantidadProp] += peso;
          detalle[dpto].familias[familia][cantidadProp] += peso;
        }
      }
      return {
        id: data.tienda.id,
        nombre: data.tienda.nombre,
        detalle: Object.keys(detalle).map((dpto) => ({
          dpto,
          ventas: detalle[dpto].ventas,
          cantidadUd: detalle[dpto].cantidadUd.toFixed(3),
          cantidadKg: detalle[dpto].cantidadKg.toFixed(3),
          importe: detalle[dpto].importe.toFixed(2),
          familias: Object.keys(detalle[dpto].familias)
            .sort()
            .map((familia) => ({
              familia,
              ventas: detalle[dpto].familias[familia].ventas,
              importe: detalle[dpto].familias[familia].importe.toFixed(2),
              cantidadUd: detalle[dpto].familias[familia].cantidadUd.toFixed(3),
              cantidadKg: detalle[dpto].familias[familia].cantidadKg.toFixed(3),
            })),
        })),
        total: {
          ventas: total.ventas,
          importe: total.importe.toFixed(2),
          cantidadUd: total.cantidadUd.toFixed(3),
          cantidadKg: total.cantidadKg.toFixed(3),
        },
      };
    },
  },
];
