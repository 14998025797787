import React from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import dayjs from 'dayjs';
import TableFooter from '@material-ui/core/TableFooter';

import { SPANISH_WEEK } from '../../helpers';
import { Table } from '@material-ui/core';

const VentasVendedor = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => (
  <Paper style={{ padding: '1em 1em 3em 1em' }}>
    {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom={true}>
          {`${reportData.nombre}: ${reportTitle}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={true} align="right">
          <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
          {formattedStartDate} / {formattedEndDate}
        </Typography>
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <TableContainer
        component={({ children }) => <Grid item>{children}</Grid>}
      >
        <Table id="report-table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={7}>TICKETS</TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vendedor</TableCell>
              <TableCell>CORRECTOS</TableCell>
              <TableCell>Anul.</TableCell>
              <TableCell>Nulos</TableCell>
              <TableCell>Anul PC</TableCell>
              <TableCell>Arqueo</TableCell>
              <TableCell>Invent.</TableCell>
              <TableCell>Mermas</TableCell>
              <TableCell>PESO (Kg)</TableCell>
              <TableCell>IMPORTE (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.detalle.map((row: any, idx: number) => (
              <TableRow key={idx}>
                <TableCell>{row.vendedor + ' ' + row.descrip}</TableCell>
                <TableCell align="right">{row.ticketsC}</TableCell>
                <TableCell align="right">{row.ticketsA}</TableCell>
                <TableCell align="right">{row.ticketsN}</TableCell>
                <TableCell align="right">{row.ticketsP}</TableCell>
                <TableCell align="right">{row.ticketsR}</TableCell>
                <TableCell align="right">{row.ticketsI}</TableCell>
                <TableCell align="right">{row.ticketsM}</TableCell>
                <TableCell align="right">{row.cantidad}</TableCell>
                <TableCell align="right">{row.importe}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">{reportData.total.ticketsC}</TableCell>
            <TableCell align="right">{reportData.total.ticketsA}</TableCell>
            <TableCell align="right">{reportData.total.ticketsN}</TableCell>
            <TableCell align="right">{reportData.total.ticketsP}</TableCell>
            <TableCell align="right">{reportData.total.ticketsR}</TableCell>
            <TableCell align="right">{reportData.total.ticketsI}</TableCell>
            <TableCell align="right">{reportData.total.ticketsM}</TableCell>
            <TableCell align="right">{reportData.total.cantidad}</TableCell>
            <TableCell align="right">{reportData.total.importe}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Grid>
  </Paper>
);

export default React.memo(VentasVendedor);
