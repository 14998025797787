import React from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { Table } from '@material-ui/core';

const VentasPLUHora = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => (
  <Paper style={{ padding: '1em 1em 3em 1em' }}>
    {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom={true}>
          {`${reportData.nombre}: ${reportTitle}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={true} align="right">
          <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
          {formattedStartDate} / {formattedEndDate}
        </Typography>
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <TableContainer
        component={({ children }) => <Grid item>{children}</Grid>}
      >
        <Table size="small" id="report-table">
          <TableHead>
            <TableRow>
              <TableCell>DPTO</TableCell>
              <TableCell>PLU</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">VENTAS</TableCell>
              <TableCell align="right">CANTIDAD</TableCell>
              <TableCell align="right">IMPORTE(€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.detalle.map((row: any, idx: number) => (
              <>
                <TableRow key={idx}>
                  <TableCell>{row.dpto}</TableCell>
                  <TableCell
                    colSpan={2}
                  >{`${row.plu} ${row.descrip}`}</TableCell>
                  <TableCell align="right">{row.ventas}</TableCell>
                  <TableCell align="right">{`${row.cantidad} ${row.unidades}`}</TableCell>
                  <TableCell align="right">{row.importe}</TableCell>
                </TableRow>
                {row.dias.map((dia: any, idx1: number) => (
                  <>
                    <TableRow key={idx + ',' + idx1}>
                      <TableCell colSpan={2}></TableCell>
                      <TableCell>{dia.dia}</TableCell>
                      <TableCell align="right">{dia.ventas}</TableCell>
                      <TableCell align="right">{`${dia.cantidad} ${row.unidades}`}</TableCell>
                      <TableCell align="right">{dia.importe}</TableCell>
                    </TableRow>
                    {dia.horas.map((hora: any, idx2: number) => (
                      <>
                        <TableRow key={idx + ',' + idx1 + ',' + idx2}>
                          <TableCell colSpan={2}></TableCell>
                          <TableCell align="right">{hora.hora}</TableCell>
                          <TableCell align="right">{hora.ventas}</TableCell>
                          <TableCell align="right">{`${hora.cantidad} ${row.unidades}`}</TableCell>
                          <TableCell align="right">{hora.importe}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Paper>
);

export default React.memo(VentasPLUHora);
