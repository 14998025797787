import React, { useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/es';

import { BrowserRouter as Router } from 'react-router-dom';

import AppContent from './AppContent';
import Oauth2Login from './Oauth2Login';
import { OAuth2Provider, OAuth2Manager } from '../contexts/Auth';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import config from '../config.json';

const ENV = process.env.NODE_ENV;

const App = () => {
  const [authManager, setAuthManager] = useState<OAuth2Manager>();
  const [client, setClient] = useState<ApolloClient<any>>();

  const handleLogin = async (code: string, verifier: string) => {
    const authManager = new OAuth2Manager({
      appURL: config[ENV].appURL,
      oauth2TokenEP: config[ENV].oauth2TokenEP,
      oauth2LogoutEP: config[ENV].oauth2LogoutEP,
      oauth2PasswordChangeEP: config[ENV].oauth2PasswordChangeEP,
      clientId: config[ENV].clientId,
      scope: config[ENV].scope,
    });
    try {
      await authManager.login(code, verifier);

      // Create context to apollo client.
      const httpLink = createHttpLink({
        uri: config[ENV].apiURL,
      });
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            Authorization: authManager.getAuthorizationHeader(),
          },
        };
      });
      setClient(
        new ApolloClient({
          link: authLink.concat(httpLink),
          cache: new InMemoryCache(),
        })
      );
      setAuthManager(authManager);
    } catch (error) {
      throw error;
    }
  };

  if (!authManager)
    return (
      <Oauth2Login
        authorizationEndpoint={config[ENV].oauth2AuthEP}
        tokenEndpoint={config[ENV].oauth2TokenEP}
        clientId={config[ENV].clientId}
        redirectURI={config[ENV].appURL}
        scope={config[ENV].scope}
        onLogin={handleLogin}
      />
    );
  else
    return (
      <OAuth2Provider
        oauth2Manager={authManager}
        unsetOauth2Manager={() => {
          setAuthManager(undefined);
        }}
      >
        <MuiPickersUtilsProvider utils={DayjsUtils} locale="es">
          <ApolloProvider client={client!}>
            <Router>
              <AppContent />
            </Router>
          </ApolloProvider>
        </MuiPickersUtilsProvider>
      </OAuth2Provider>
    );
};

export default App;
