import React from 'react';
import { ReportTemplateProps } from '../types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import dayjs from 'dayjs';
import TableFooter from '@material-ui/core/TableFooter';

import { SPANISH_WEEK } from '../../helpers';
import { Table } from '@material-ui/core';

const Arqueo = ({
  reportTitle,
  formattedStartDate,
  formattedEndDate,
  reportData,
}: ReportTemplateProps) => (
  <Paper style={{ padding: '1em 1em 3em 1em' }}>
    {/* Shop: {shop}, Inicio: {startDate}, Fin: {endDate} */}
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom={true}>
          {`${reportData.nombre}: ${reportTitle}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={true} align="right">
          <span style={{ fontWeight: 'bold' }}>Intervalo fechas:</span>{' '}
          {formattedStartDate} / {formattedEndDate}
        </Typography>
      </Grid>
    </Grid>
    <Grid container justifyContent="center">
      <TableContainer
        component={({ children }) => <Grid item>{children}</Grid>}
      >
        <Table id="report-table">
          <TableHead>
            <TableRow>
              <TableCell>FECHA</TableCell>
              <TableCell></TableCell>
              <TableCell>TARJETA(€)</TableCell>
              <TableCell>VARIOS(€)</TableCell>
              <TableCell>CAJA(€)</TableCell>
              <TableCell>DOTACIÓN(€)</TableCell>
              <TableCell>EFECTIVO(€)</TableCell>
              <TableCell>Diferencia(€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.detalle.map((row: any, idx: number) => (
              <TableRow key={idx}>
                <TableCell>{row.dia}</TableCell>
                <TableCell>
                  {SPANISH_WEEK[dayjs(row.dia, 'DD/MM/YYYY').day()]}
                </TableCell>
                <TableCell align="right">{row.pagoTarjeta}</TableCell>
                <TableCell align="right">{row.pagoVarios}</TableCell>
                <TableCell align="right">{row.importeR}</TableCell>
                <TableCell align="right">{row.importeD}</TableCell>
                <TableCell align="right">{row.importeC}</TableCell>
                <TableCell align="right">
                  {row.difArqueo + ' ' + row.sobraFalta}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={2}>TOTAL</TableCell>
            <TableCell align="right">{reportData.total.pagoTarjeta}</TableCell>
            <TableCell align="right">{reportData.total.pagoVarios}</TableCell>
            <TableCell align="right">{reportData.total.importeR}</TableCell>
            <TableCell align="right">{reportData.total.importeD}</TableCell>
            <TableCell align="right">{reportData.total.importeC}</TableCell>
            <TableCell align="right">
              {reportData.total.difArqueo + ' ' + reportData.total.sobraFalta}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Grid>
  </Paper>
);

export default React.memo(Arqueo);
